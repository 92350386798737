import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

import ai from '../../images/Icon AI.svg';
import students from '../../images/Icon Students.svg';
import ondemand from '../../images/Icon On demand.svg';
import crypto from '../../images/Icon Cryptocurrency.svg';
import collaboration from '../../images/Icon Collaboration.svg';
import affordable from '../../images/Icon Affordable.svg';
import quotes from '../../images/quote mark.svg';

const Features = props => (
  <Layout>
    <SEO title="Features" />
    <section className="features" id="features">
      <div className="features-header">
        <p className="features-header-title">FEATURES</p>
        <div className="features-header-grid">
          <div className="features-header-grid-item">
            <img alt="ai" src={ai} />
            <p className="features-header-grid-item-title">Personal AI</p>
            <p className="features-header-grid-item-description">
              Each learner gets an AI that adapts and grows in its understanding
              of them over time
            </p>
          </div>
          <div className="features-header-grid-item">
            <img alt="students" src={students} />
            <p className="features-header-grid-item-title">Live Streaming</p>
            <p className="features-header-grid-item-description">
              Every learner has instant micro education, live-streamed to them, anytime, anywhere
            </p>
          </div>
          <div className="features-header-grid-item">
            <img alt="ondemand" src={ondemand} />
            <p className="features-header-grid-item-title">Micro Bites</p>
            <p className="features-header-grid-item-description">
              Learning is created in small blocks called micro-bites enabling members to access the right content at the right time
            </p>
          </div>
          <div className="features-header-grid-item">
            <img alt="crypto" src={crypto} />
            <p className="features-header-grid-item-title">Earning Through Content Creation</p>
            <p className="features-header-grid-item-description">
              Members can earn income by creating micro-bites giving them a source of royalty revenue
            </p>
          </div>
          <div className="features-header-grid-item">
            <img alt="collaboration" src={collaboration} />
            <p className="features-header-grid-item-title">Club Wide Social Communication and Sharing</p>
            <p className="features-header-grid-item-description">
              Every learner can seamlessly connect with other learners in their community
            </p>
          </div>
          <div className="features-header-grid-item">
            <img alt="affordable" src={affordable} />
            <p className="features-header-grid-item-title">Shared Subscription Revenue</p>
            <p className="features-header-grid-item-description last-description">Clubs can earn income from the platform creating a new source of revenue</p>
          </div>
        </div>
      </div>
      <div className="features-footer">
        <img alt="quotes" src={quotes} />
        <p className="features-footer-text">
          This is the future of education - dynamic, interactive and personal
        </p>
      </div>
    </section>
  </Layout>
);

export default Features;
